<template>
    <div>
        <Newheader />
        <div class="main">



    
<div class="motor_type">
  <div class="video">
    <img style="height: 100vh;object-fit:cover;" src="https://ckmco-video.oss-cn-shanghai.aliyuncs.com/2021/eleimg/images/i_one_xs_video_bg_1.jpg" alt="">
    <div class="start">
        <img src="https://qiniu.kymco.life/img/f9_video_bg_1.png">
    </div>
    <div class="video_content" style="display: none">
      <div class="cancel"><span class="close">x</span></div>
      <video autoplay="" loop="" muted="" controls="">
        <source src="https://ckmco-video.oss-cn-shanghai.aliyuncs.com/2021/eleimg/video/ione-xs.mp4"> 
      </video>
    </div>
  </div>




  <div class="carousels wow animated fadeInUp">

            <div class="mains">

                <div id="carousel-example-generic" class="carousel slide" data-ride="carousel">

                    <!-- Indicators -->

                    <ol class="carousel-indicators">

                        <li data-target="#carousel-example-generic" data-slide-to="0" class="active"><span></span></li>

                        <li data-target="#carousel-example-generic" data-slide-to="1"><span></span></li>

                        <li data-target="#carousel-example-generic" data-slide-to="2"><span></span></li>

          <li data-target="#carousel-example-generic" data-slide-to="3"><span></span></li>
                    </ol>



                    <!-- Wrapper for slides -->

                    <div class="carousel-inner" role="listbox">

                        <div class="item active">

                            <img src="https://ckmco-video.oss-cn-shanghai.aliyuncs.com/2021/eleimg/images/i_one_xs_video_carousel_1.jpg">

                            <div class="carousel-caption">

                                <div class="carousel_tit">

                                    

                                </div>

                                <div class="carousel_more">

                                

                                </div>

                            </div>

                        </div>

                        <div class="item">

                            <img src="https://ckmco-video.oss-cn-shanghai.aliyuncs.com/2021/eleimg/images/i_one_xs_video_carousel_2.jpg">

                            <div class="carousel-caption">

                                <div class="carousel_tit">


                                </div>

                                <div class="carousel_more">

                                

                                </div>

                            </div>

                        </div>

                        <div class="item">

                            <img src="https://ckmco-video.oss-cn-shanghai.aliyuncs.com/2021/eleimg/images/i_one_xs_video_carousel_3.jpg">

                            <div class="carousel-caption">

                                <div class="carousel_tit">

                                    

                                </div>

                                <div class="carousel_more">

                                    

                                </div>

                            </div>

                        </div>
          <div class="item">

                            <img src="https://ckmco-video.oss-cn-shanghai.aliyuncs.com/2021/eleimg/images/i_one_xs_video_carousel_4.jpg">

                            <div class="carousel-caption">

                                <div class="carousel_tit">

                                

                                </div>

                                <div class="carousel_more">

                                    

                                </div>

                            </div>

                        </div>

                    </div>

                    <!-- Controls -->

                    <a class="left carousel-control" href="#carousel-example-generic" role="button" data-slide="prev">

                        <span class="glyphicon glyphicon-chevron-left" aria-hidden="true"></span>

                        <span class="sr-only">Previous</span>

                    </a>

                    <a class="right carousel-control" href="#carousel-example-generic" role="button" data-slide="next">

                        <span class="glyphicon glyphicon-chevron-right" aria-hidden="true"></span>

                        <span class="sr-only">Next</span>

                    </a>

                </div>

            </div>

        </div>







 
  
<!-- footer -->

<!-- 蒙层 -->

<div class="mantle" id="mantle"></div>

</div>
<div class="wap-warp">
       <img class="starts" src="https://qiniu.kymco.life/images/wap/ionexs_video.png">
       <img src="https://ckmco-video.oss-cn-shanghai.aliyuncs.com/2021/eleimg/images/i_one_xs_video_carousel_1.jpg">
    <img src="https://ckmco-video.oss-cn-shanghai.aliyuncs.com/2021/eleimg/images/i_one_xs_video_carousel_2.jpg">
    <img src="https://ckmco-video.oss-cn-shanghai.aliyuncs.com/2021/eleimg/images/i_one_xs_video_carousel_3.jpg">
    <img src="https://ckmco-video.oss-cn-shanghai.aliyuncs.com/2021/eleimg/images/i_one_xs_video_carousel_4.jpg">
   </div>

</div><div class="main">



    
    <div class="motor_type">
      <div class="video">
        <img style="height: 100vh;object-fit:cover;" src="https://ckmco-video.oss-cn-shanghai.aliyuncs.com/2021/eleimg/images/i_one_xs_video_bg_1.jpg" alt="">
        <div class="start">
            <img src="https://qiniu.kymco.life/img/f9_video_bg_1.png">
        </div>
        <div class="video_content" style="display: none">
          <div class="cancel"><span class="close">x</span></div>
          <video autoplay="" loop="" muted="" controls="">
            <source src="https://ckmco-video.oss-cn-shanghai.aliyuncs.com/2021/eleimg/video/ione-xs.mp4"> 
          </video>
        </div>
      </div>




      <div class="carousels wow animated fadeInUp">

				<div class="mains">

					<div id="carousel-example-generic" class="carousel slide" data-ride="carousel">

						<!-- Indicators -->

						<ol class="carousel-indicators">

							<li data-target="#carousel-example-generic" data-slide-to="0" class="active"><span></span></li>

							<li data-target="#carousel-example-generic" data-slide-to="1"><span></span></li>

							<li data-target="#carousel-example-generic" data-slide-to="2"><span></span></li>

              <li data-target="#carousel-example-generic" data-slide-to="3"><span></span></li>
						</ol>



						<!-- Wrapper for slides -->

						<div class="carousel-inner" role="listbox">

							<div class="item active">

								<img src="https://ckmco-video.oss-cn-shanghai.aliyuncs.com/2021/eleimg/images/i_one_xs_video_carousel_1.jpg">

								<div class="carousel-caption">

									<div class="carousel_tit">

										

									</div>

									<div class="carousel_more">

									

									</div>

								</div>

							</div>

							<div class="item">

								<img src="https://ckmco-video.oss-cn-shanghai.aliyuncs.com/2021/eleimg/images/i_one_xs_video_carousel_2.jpg">

								<div class="carousel-caption">

									<div class="carousel_tit">


									</div>

									<div class="carousel_more">

									

									</div>

								</div>

							</div>

							<div class="item">

								<img src="https://ckmco-video.oss-cn-shanghai.aliyuncs.com/2021/eleimg/images/i_one_xs_video_carousel_3.jpg">

								<div class="carousel-caption">

									<div class="carousel_tit">

										

									</div>

									<div class="carousel_more">

										

									</div>

								</div>

							</div>
              <div class="item">

								<img src="https://ckmco-video.oss-cn-shanghai.aliyuncs.com/2021/eleimg/images/i_one_xs_video_carousel_4.jpg">

								<div class="carousel-caption">

									<div class="carousel_tit">

									

									</div>

									<div class="carousel_more">

										

									</div>

								</div>

							</div>

						</div>

						<!-- Controls -->

						<a class="left carousel-control" href="#carousel-example-generic" role="button" data-slide="prev">

							<span class="glyphicon glyphicon-chevron-left" aria-hidden="true"></span>

							<span class="sr-only">Previous</span>

						</a>

						<a class="right carousel-control" href="#carousel-example-generic" role="button" data-slide="next">

							<span class="glyphicon glyphicon-chevron-right" aria-hidden="true"></span>

							<span class="sr-only">Next</span>

						</a>

					</div>

				</div>

			</div>







     
  	
    <!-- footer -->

    <!-- 蒙层 -->

    <div class="mantle" id="mantle"></div>

  </div>
   <div class="wap-warp">
   		<img class="starts" src="https://qiniu.kymco.life/images/wap/ionexs_video.png">
   		<img src="https://ckmco-video.oss-cn-shanghai.aliyuncs.com/2021/eleimg/images/i_one_xs_video_carousel_1.jpg">
		<img src="https://ckmco-video.oss-cn-shanghai.aliyuncs.com/2021/eleimg/images/i_one_xs_video_carousel_2.jpg">
		<img src="https://ckmco-video.oss-cn-shanghai.aliyuncs.com/2021/eleimg/images/i_one_xs_video_carousel_3.jpg">
		<img src="https://ckmco-video.oss-cn-shanghai.aliyuncs.com/2021/eleimg/images/i_one_xs_video_carousel_4.jpg">
   	</div>
 
</div>
    </div>
</template>
<script>
    export default{
        data(){
            return{

            }
        }
    }
</script>
<style>
</style>